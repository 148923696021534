import { Box, Button, Container, Divider, LinearProgress } from "@material-ui/core";
import React from "react";
import { PreRoomAppBar } from "../components/PreRoomAppBar";
import { RoomCreate } from "../components/RoomCreate";
import { RoomForm } from "../components/RoomForm";
import { AuthService } from "../services/AuthService";
import { useObservable } from "../utils";

export const PreRoomContainer = () => {
  const userOrFalse: firebase.User | null | undefined  = useObservable(AuthService.Instance.user$);
  console.log("PreRoomContainer", userOrFalse);

  const logInWithGoogle = () => {
    AuthService.Instance.signInWithGoogle();
  }

  const logInWithGitHub = () => {
    AuthService.Instance.signInWithGithub();
  }

  if (!userOrFalse) {
    return (
      <Container maxWidth="xs">
        <p>Connecting</p>
        <LinearProgress color="secondary" />
      </Container>
    );
  }

  return (
    <>
      <PreRoomAppBar />
      <Box
        style={{
          paddingTop: "50px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around"
        }}
      >
        <Container maxWidth="xs">
          {
            userOrFalse!.isAnonymous ? (
              <>
                <Button onClick={logInWithGoogle} variant="contained">Log in with Google</Button>
                <Button onClick={logInWithGitHub} variant="contained">Log in with GitHub</Button>
              </>
            ) : (
              <RoomCreate loggedIn={!!userOrFalse} />
            )
          }
        </Container>
        <Divider />
        <Container maxWidth="xs">
          <RoomForm />
        </Container>
      </Box>
    </>
  );
};
