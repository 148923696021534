const firebaseConfig = {
  apiKey: "AIzaSyCU72WxLwJd36smiJo8az78s3bmYbefDXc",
  authDomain: "workhealthy-dev.firebaseapp.com",
  databaseURL: "https://workhealthy-dev.firebaseio.com",
  projectId: "workhealthy-dev",
  storageBucket: "workhealthy-dev.appspot.com",
  messagingSenderId: "487456725380",
  appId: "1:487456725380:web:617dc5eb2531350076e5d1",
  measurementId: "G-N7BTZMWXP9"
};

export default firebaseConfig;

