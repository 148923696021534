import React from "react";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { PreRoomContainer } from "./containers/PreRoomContainer";
import { RoomContainer } from "./containers/RoomContainer";
import { TitleBarSwitcher } from "./components/TitleBarSwitcher";
import { AuthService } from "./services/AuthService";
import { useObservable } from "./utils";


const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#edffff",
      main: "#baddf9",
      dark: "#89abc6"
    },
    secondary: {
      light: "#ffffff",
      main: "#ffcdd2",
      dark: "#cb9ca1"
    }
  }
});


export default function App() {
  const user = useObservable(AuthService.Instance.user$);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App" style={{ minWidth: 375 }}>
          <TitleBarSwitcher />
          <Switch>
            <Route exact path="/">
              <PreRoomContainer />
            </Route>
            <Route path="/room/:roomId">
              <RoomContainer user={user} />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}
