import React from "react";

import { EnvironmentService } from "../services/EnvironmentService";
import { useObservable } from "../utils";

const ENVIRONMENT_TITLES = {
    local: "LOCAL sidsi",
    dev: "DEV sidsi",
    staging: "STAGING sidsi",
    production: "(-: sidsi :-)",
    default: "Loading...",
};

export const TitleBarSwitcher = () => {
    const environment = useObservable(EnvironmentService.Instance.environment$);

    switch(environment) {
        case "dev":         document.title = ENVIRONMENT_TITLES.dev; break;
        case "staging":     document.title = ENVIRONMENT_TITLES.staging; break;
        case "production":  document.title = ENVIRONMENT_TITLES.production; break;
        default:            document.title = ENVIRONMENT_TITLES.default; break;
    }

    return <></>
}
