import Firebase, { User } from "firebase";
import { authState } from "rxfire/auth";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FirebaseService } from "./FirebaseService";

export class AuthService {
  private static _instance: AuthService;

  auth: firebase.auth.Auth;
  loggedIn$: Observable<Firebase.User | boolean>;
  user$: Observable<User | null>;

  private constructor() {
    this.auth = FirebaseService.Instance.app.auth();
    
    this.user$ = authState(this.auth);
    this.loggedIn$ = authState(this.auth).pipe(map((user) => !!user));

    // TODO: catch errors and send them down loggedIn$
    if (this.auth.currentUser === null) {
      this.auth.signInAnonymously();
    }
  }

  public signInWithGoogle() {
    // Using a popup.
    const provider = new Firebase.auth.GoogleAuthProvider();
    Firebase.auth().signInWithPopup(provider).then(function(result) {
      if (result) {
        // This gives you a Google Access Token.
        const credential = result!.credential!;
        const user = result.user;
        console.log(credential, user);
        // The signed-in user info.
      }
    });
  }

  public signInWithGithub() {
    // Using a popup.
    const provider = new Firebase.auth.GithubAuthProvider();
    Firebase.auth().signInWithPopup(provider).then(function(result) {
      if (result) {
        // This gives you a Google Access Token.
        const credential = result!.credential!;
        const user = result.user;
        console.log(credential, user);
        // The signed-in user info.
      }
    });
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}
